window.addEventListener("load", function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.cookieconsent.initialise({
        "content": {
            "message": "By using the website, you agree that we use cookies.",
            "dismiss": "OK!",
            "href": "/privacy-policy/"
        },
        onPopupOpen: function () {
            document.body.classList.add('cookie-banner-open');
        },
        onPopupClose: function () {
            document.body.classList.remove('cookie-banner-open');
        }
    })
});