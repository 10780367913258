import { ComponentRegistry, CssSelector } from './component-registry';

export class Component<T extends HTMLElement = HTMLElement> {
  constructor(protected readonly host: T) {

  }

  onInit() {
    // NOTE: empty implementation on purpose
  }

  static declare(selector: CssSelector) {
    ComponentRegistry.declare(selector, this);
  }
}
