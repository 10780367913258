export function scrollAnimatedTo(targetEl: HTMLElement, offset = 0) {
  const currScrollTop = window.scrollY || document.documentElement.scrollTop;
  const headerHeight = Math.min(jQuery('#navigation').height(), 80);
  const newScrollTop = currScrollTop + targetEl.getBoundingClientRect().top - headerHeight - offset;

  jQuery('html, body').animate(
    {
      scrollTop: newScrollTop
    },
    500,
    'swing',
  );
}