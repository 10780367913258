
/**
 * Onepager Navigation Actives
 */

function unsetAllActives() {
    const allActives = Array.from(document.querySelectorAll('.navbar-nav .nav-link.active'));

    for (const activeNav of allActives) {
        activeNav.classList.remove('active')
    }
}

function callback(entries) {
    for (const entry of entries) {
        const hash = '#' + entry.target.id;
        const navEl = document.querySelector(`a[href="${hash}"]`);
        const sectionEl = document.querySelector(`section${hash}`);

        if (!navEl) {
            continue;
        };

        if (entry.isIntersecting && entry.intersectionRatio >= 0.55) {
            unsetAllActives()
            navEl.classList.add('active');

            if (sectionEl) {
                sectionEl.classList.remove('fade-out');
            }
        }
    }
}


const myIntersectionObserver = new IntersectionObserver(callback, {
    root: null,
    rootMargin: '0px',
    threshold: 0.56,
});

const allSections = Array.from(document.querySelectorAll('section[id]'));

for (const section of allSections) {
    myIntersectionObserver.observe(section);
}


const navLinks = document.querySelectorAll('.navbar-nav .nav-link');
const onHome = window.location.pathname == '/';

if (!onHome) {
    navLinks.forEach(navLink => {
        const hrefValue = navLink.getAttribute('href');
        if (hrefValue.startsWith('#')) {
            navLink.setAttribute('href', `/${hrefValue}`);
        }
    });
}
