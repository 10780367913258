import { Component } from '../../../libs/components/component';


class NavbarToggler extends Component {
    private isOpen = false;
    private navbarEl: HTMLElement;
    
    private get navbarHeight(): number {
        return this.navbarEl.querySelector('.navbar-nav').clientHeight;
    }

    private openNavigation() {
        this.isOpen = true;
        this.navbarEl.classList.add('collapsing', 'show');
        this.navbarEl.style.height = `${this.navbarHeight + 20}px`;
        setTimeout(() => {
            this.navbarEl.classList.remove('collapsing');
            this.navbarEl.removeAttribute('style');
        }, 350);
    }

    private closeNavigation() {
        this.isOpen = false;
        this.navbarEl.style.height = `${this.navbarHeight + 20}px`;
        this.navbarEl.classList.add('collapsing');
        setTimeout(() => {
            this.navbarEl.removeAttribute('style');
        }, 1)
        setTimeout(() => {
            this.navbarEl.classList.remove('collapsing', 'show');
        }, 350);
    }

    onInit() {
        this.host.addEventListener('click', (event) => {
            event.preventDefault();
            if (this.isOpen) {
                this.closeNavigation();
            } else {
                this.openNavigation();
            }
        });

        this.navbarEl = this.host.closest('.navbar').querySelector('.navbar-collapse');
    }
}
NavbarToggler.declare('.navbar-toggler');