import './jquery.flexslider-min.js';


$(window).on('load', function () {
    const $bgSlideshow = $('.intro-slider').find('.flexslider');
    $bgSlideshow.flexslider({
        selector: '.slides > .bg-cover',
        easing: 'linear',
        slideshowSpeed: 7500,
        animationSpeed: 1500,
        controlNav: false,
        directionNav: false,
        keyboard: false,
        pauseOnAction: true,
        touch: false,
        after: function (slider) {
            if (!slider.playing) {
                slider.play();
            }
        }
    });
});