
function callback(entries) {
    for (const entry of entries) {
        const hash = '#' + entry.target.id;
        const sectionEl = document.querySelector(`section${hash}`);

        if (entry.isIntersecting) {
            if (sectionEl) {
                sectionEl.classList.remove('fade-out');
            }
        }
    }
}


const intersectionTreshold = (window.innerWidth < 500 || window.innerHeight < 700) ? 0 : 0.3;


const myIntersectionObserver = new IntersectionObserver(callback, {
    root: null,
    rootMargin: '0px',
    threshold: intersectionTreshold,
});

const allSections = Array.from(document.querySelectorAll('section[id]'));

for (const section of allSections) {
    myIntersectionObserver.observe(section);
}