import { scrollAnimatedTo } from "../../../libs/helpers";
import { Component } from '../../../libs/components/component';


class SmoothScrollingLink extends Component {
  onInit() {
    this.host.addEventListener('click', (event) => {
      const clickTarget = event.currentTarget as HTMLAnchorElement;
      const targetEl = document.querySelector(
        clickTarget.getAttribute('href').replace(/ /g, '\\ ')
      );

      if (targetEl) {
        event.preventDefault();
        scrollAnimatedTo(targetEl as HTMLElement);
      }
    });
  }
}
SmoothScrollingLink.declare('a[href^="#"]');



/**
 * Handle page scroll when entering page (with page reload) with hash.
 */
(() => {
  if (window.location.hash) {
    const targetEl = document.querySelector(window.location.hash);
    window.onload = function() {
      scrollAnimatedTo(targetEl as HTMLElement);
    };
  }
})();
